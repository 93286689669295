import React from 'react';

import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import twitter from '../images/twitter.svg';

const Socials = ({ Link }) => {
  return (
    <>
      <Link
        href="https://www.instagram.com/racehorsenft"
        target="_blank"
        rel="noopener"
        title="Instagram"
        track={{ event: 'instagram' }}
      >
        <img src={instagram} width={30} height={30} alt="Instagram" />
      </Link>
      <Link
        href="https://t.me/+1vdQpuLwFlhhZTA0"
        target="_blank"
        rel="noopener"
        title="Telegram"
        track={{ event: 'telegram' }}
      >
        <img src={telegram} width={30} height={30} alt="Telegram" />
      </Link>
      <Link
        href="https://twitter.com/racehorsenft"
        target="_blank"
        rel="noopener"
        title="Twitter"
        track={{ event: 'twitter' }}
      >
        <img
          src={twitter}
          width={30}
          height={30}
          alt="Twitter"
          style={{ borderRadius: 5 }}
        />
      </Link>
    </>
  );
};

export default Socials;
