import React from 'react';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import { AutoLayout } from '@digigear/elements';
import IconButton from '@mui/material/IconButton';

const StyledAutoLayout = styled(AutoLayout)`
  border: solid 2px #000;
  background: #111;
  color: #fff;
  width: 100%;

  .title-container {
    padding: 30px;
    background: ${props => props.theme.palette.primary.main};

    font-weight: 700;
    font-size: 24px;
    line-height: 19px;
    color: #000;
  }

  .close-button {
    color: #000;
  }

  .children {
    padding: 30px;
  }
`;

const Container = ({ title, style, children, onClose }) => {
  return (
    <StyledAutoLayout direction="column" stlye={style}>
      <AutoLayout
        direction="row"
        className="title-container"
        alignItems="center"
        justifyContent="space-between"
      >
        {title}

        {onClose && (
          <IconButton
            className="close-button"
            aria-label="close"
            onClick={onClose}
          >
            <MdClose />
          </IconButton>
        )}
      </AutoLayout>
      <div className="children">{children}</div>
    </StyledAutoLayout>
  );
};

export default Container;
