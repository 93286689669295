import { cloneDeep, set } from 'lodash';
import { Config } from './config';

const env = process.env.GATSBY_ENV || 'development';

let config: Config = require(`./${env}`).default;

if (!config) {
  throw new Error(`Missing configuration for ${env}`);
}

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  const hash = window.location.hash.substr(1);
  if (hash) {
    config = cloneDeep(config);
    for (const [k, v] of new URLSearchParams(hash)) {
      set(config, k, v);
    }
  }
  console.log('Dev config override', config);
}

export default config;
