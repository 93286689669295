import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BaseHeader } from '@digigear/elements';
import { useWeb3React } from '@web3-react/core';

import logo from '../images/logo.svg';
import Button from './Button';
import NavLink from './NavLink';
import Socials from './Socials';
import WalletButton from './WalletButton';

const Logo = () => (
  <Link to="/" className="logo">
    <img
      src={logo}
      width={105}
      height={42}
      alt="IRON"
      style={{ display: 'block' }}
    />
  </Link>
);

const StyledHeader = styled(BaseHeader)`
  transition: background-color 0.2s;

  background-color: #181f26;

  & > .content > * + * {
    margin-left: 25px;
  }

  @media screen and (max-width: 1280px) {
    & > .content ${NavLink} {
      padding: 12.5px 6px;
    }

    ${NavLink} {
    }

    .member {
      font-size: 12px !important;
      white-space: pre;
    }
  }

  @media screen and (max-width: 1080px) {
    ${NavLink} {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 600px) {
    .logo {
      width: 70px;
      height: 28px;
    }

    .wallet-button {
      padding: 16px 12px;
    }

    & > .content > * + * {
      margin-left: 10px;
    }
  }
`;

const Header = () => {
  const { active, account } = useWeb3React();
  return (
    <StyledHeader
      fixed
      logo={<Logo />}
      cta={<WalletButton>Connect Wallet</WalletButton>}
      placeholderProps={{ style: { background: '#181f26' } }}
    >
      <NavLink to="/#about-us">About Us</NavLink>
      <NavLink to="/#features">Features</NavLink>
      <NavLink to="/#memberships">Memberships</NavLink>
      <NavLink to="/#roadmap">Roadmap</NavLink>
      {/* <NavLink to="/faqs">FAQs</NavLink> */}
      <div style={{ flex: 1 }} />
      <Socials Link={NavLink} />
      {active && account && (
        <Button
          href={`//${window.location.hostname.replace(
            /racehorsenft\.io/,
            'portal.racehorsenft.io'
          )}/`}
          className="member"
          style={{ alignSelf: 'center' }}
        >
          Members{'\n'}Portal
        </Button>
      )}
    </StyledHeader>
  );
};

export default Header;
