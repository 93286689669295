import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AutoLayout } from '@digigear/elements';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import Container from './Container';

const StyledPlainDialog = styled(Dialog)`
  .MuiPaper-root {
    background: transparent;
    max-height: 400px;
    min-width: 400px;
    overflow-y: hidden;
    box-shadow: none;
  }
`;

const Transition = React.forwardRef(function Transition(transitProps, ref) {
  return <Slide direction="up" ref={ref} {...transitProps} />;
});

const PlainDialog = ({
  open,
  title,
  // transitionDirection,
  children,
  onClose,
  contentStyle,
  ...props
}) => {
  return (
    <div>
      <StyledPlainDialog
        open={open}
        TransitionComponent={Transition}
        {...props}
      >
        <AutoLayout direction="row" gap={15} alignItems="flex-start">
          <Container title={title} onClose={onClose} children={children} />
        </AutoLayout>
      </StyledPlainDialog>
    </div>
  );
};

PlainDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  transitionDirection: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  children: PropTypes.node,
  contentStyle: PropTypes.object,
};

export default PlainDialog;
