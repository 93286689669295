import * as React from 'react';
import styled from 'styled-components';

import { AutoLayout, ResponsiveLayout } from '@digigear/elements';

import NavLink2 from './NavLink';
import Socials from './Socials';

const NavLink = styled(NavLink2)`
  &,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.palette.primary.main};
  }

  text-transform: none;

  font-size: 12px;
`;

const Container = styled.footer``;

const Content = styled(ResponsiveLayout)`
  margin-left: auto;
  margin-right: auto;

  justify-content: space-between;

  max-width: ${props => props.theme.dimensions.pageWidth};
  height: 80px;

  padding: 0 32px;

  font-size: 12px;

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: ${props => props.breakpoint}px) {
    & > * {
      align-items: center;
    }
  }
`;

const Footer = () => (
  <Container>
    <Content alignItems="center" gap={17} vertical={{ gap: 0 }}>
      <AutoLayout
        direction="row"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        Copyright © 2022 International Racehorse Owners Network Limited
      </AutoLayout>
      <div style={{ flex: 1 }} />
      <NavLink to="/terms-and-conditions">Terms and Conditions</NavLink>
      <NavLink to="/privacy-policy">Privacy Policy</NavLink>
      <AutoLayout direction="row" gap={4}>
        <Socials Link={NavLink} />
      </AutoLayout>
    </Content>
  </Container>
);

export default Footer;
