import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { Link } from 'gatsby';
import { ElementsConfig } from '@digigear/elements';
import CssBaseline from '@mui/material/CssBaseline';
import { Web3ReactProvider } from '@web3-react/core';

import WalletModalProvider from './components/WalletModalProvider';
import GlobalStyle from './theme/globalStyle';
import theme from './theme/theme';

const queryClient = new QueryClient();

export const RootProviders = ({ children, getWeb3Library }) => {
  return (
    <Web3ReactProvider getLibrary={getWeb3Library}>
      <QueryClientProvider client={queryClient}>
        <ElementsConfig.Provider value={{ Link }}>
          {children}
        </ElementsConfig.Provider>
      </QueryClientProvider>
    </Web3ReactProvider>
  );
};

export const PageProviders = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      <WalletModalProvider>{children}</WalletModalProvider>
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop
        theme="light"
      />
    </ThemeProvider>
  );
};
