// import firebase from 'gatsby-plugin-firebase';
// import 'firebase/analytics';

export const trackingContext = {
  trackEvent: ({ event, ...data }) => {
    // try {
    //   console.log('Logging event', event, data);
    //   firebase.analytics().logEvent(event, data);
    // } catch (e) {
    //   console.warn('Tracking error', e);
    // }
  },
};
