import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AutoLayout } from '@digigear/elements';
import { useWeb3React } from '@web3-react/core';

import coinbaseIcon from '../images/coinbase.png';
import metamaskIcon from '../images/metamask.png';
import walletconnectIcon from '../images/walletconnect.png';
import { coinbaseWallet, injected, walletConnect } from '../web3/connector';
import Button from './Button';
import PlainDialog from './PlainDialog';

export interface UseWalletModal {
  visible: boolean;
  setVisible(visible: boolean): void;
}

export const WalletModalContext = createContext<UseWalletModal | null>(null);

const WalletImage = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 12px;
`;

export const WalletModalProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const { active, activate } = useWeb3React();

  const [visible, setVisible] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      setError(null);
    }
  }, [visible]);

  useEffect(() => {
    if (active) {
      close();
    }
  }, [active]);

  const close = () => {
    setVisible(false);
  };

  const onSelectConnector = (connector: any) => {
    setIsActivating(true);
    activate(connector, undefined, true).then(
      () => {
        setIsActivating(false);
      },
      e => {
        setIsActivating(false);
        setError(e);
      }
    );
  };

  let dialog;

  if (error) {
    let errorMessage;
    console.log('Wallet connection error', error);
    if (error.toString().indexOf('No Ethereum provider') !== -1) {
      errorMessage = 'Non-Ethereum browser detected. Please install MetaMask';
    } else if (error.toString().indexOf('Unsupported chain id') !== -1) {
      errorMessage = 'Please switch to Ethereum Mainnet.';
    } else {
      errorMessage = 'Wallet connection failed. Please try again.';
    }
    dialog = (
      <PlainDialog
        open={visible}
        title="Error"
        onClose={close}
        contentStyle={{}}
      >
        <AutoLayout
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={4}
        >
          <div className="text">{errorMessage}</div>
          <Button size="small" onClick={() => setError(null)}>
            OK
          </Button>
        </AutoLayout>
      </PlainDialog>
    );
  } else {
    dialog = (
      <PlainDialog
        open={visible}
        title="Connect Wallet"
        onClose={close}
        contentStyle={{}}
      >
        <AutoLayout direction="column" justifyContent="center" gap={12}>
          {isActivating ? (
            <>Connecting...</>
          ) : (
            <>
              <Button onClick={() => onSelectConnector(coinbaseWallet)}>
                <WalletImage src={coinbaseIcon} alt="coinbase" />
                Coinbase
              </Button>
              <Button onClick={() => onSelectConnector(walletConnect)}>
                <WalletImage src={walletconnectIcon} alt="walletconnect" />
                Wallet Connect
              </Button>
              <Button onClick={() => onSelectConnector(injected)}>
                <WalletImage src={metamaskIcon} alt="metamask" />
                MetaMask
              </Button>
            </>
          )}
        </AutoLayout>
      </PlainDialog>
    );
  }

  const context = {
    visible,
    setVisible,
  };

  return (
    <WalletModalContext.Provider value={context}>
      {children}
      {dialog}
    </WalletModalContext.Provider>
  );
};

export default WalletModalProvider;

export const useWalletModal = () => {
  const modal = useContext(WalletModalContext);
  if (!modal) {
    throw new Error('No WalletModalContext provided');
  }
  return modal;
};
