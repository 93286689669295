import styled from 'styled-components';

import { BaseButton } from '@digigear/elements';

const NavLink = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12.5px;

  transition: color 0.4s, background 0.4s;

  font-size: 16px;
  text-transform: uppercase;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }

  &,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
  }

  &:hover,
  &:active {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export default NavLink;
