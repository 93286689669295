import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;

    color: #ffffff;
    background: #181f26;

    font-family: "M PLUS 1", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;

    font-weight: 900;
  }
  h1 {
    font-size: 48px;
    /* line-height: 70px; */
    margin-bottom: 30px;
  }
  h2 {
    font-size: 30px;
    line-height: 35px;
    font-weight: 800;
  }
  h3 {
    font-size: 22px;
    line-height: 32px;
  }
  h4 {
    font-size: 19px;
  }

  .body-m {
    font-size: 18px;
    line-height: 30px;
  }
  .body-s {
    font-size: 14px;
    line-height: 24px;
  }
  .tiny-sss {
    font-size: 12px;
    line-height: 17px;
  }

  .content-regular {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .content-strong {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  a {
    color: inherit;
  }

  p {
    margin: 0;
  }

  // Workaround for Mui's style not applying
  .MuiBackdrop-root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
  }
`;

export default GlobalStyle;
