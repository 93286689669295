import { Config } from './config';

const config: Config = {
  web3: {
    chain: {
      chainId: 1,
      rpcUrl: 'https://mainnet.infura.io/v3/722606095eae42c38f40c6c132fc2384',
      explorerUrl: 'https://etherscan.io/tx',
      gasSymbol: 'ETH',
    },

    addresses: {
      ERC721: '0x4400b9284498b91c37DfFBfbeb80EfB347580F1e',
      Multicall: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
    },
  },

  backendUrl: 'https://backend.racehorsenft.io/api/v1',

  whitelistUrl:
    'https://uqfh5khuuiyxbzbzaedd2lj45u0hzjsj.lambda-url.ap-east-1.on.aws/api/v1/whitelists/racehorse-nft-whitelist',
};

export default config;
