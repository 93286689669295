import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import config from '../config';

export const coinbaseWallet = new WalletLinkConnector({
  url: config.web3.chain.rpcUrl,
  appName: 'Racehorse NFT',
  supportedChainIds: [config.web3.chain.chainId],
});

export const walletConnect = new WalletConnectConnector({
  chainId: config.web3.chain.chainId,
  rpc: { [config.web3.chain.chainId]: config.web3.chain.rpcUrl },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const injected = new InjectedConnector({
  supportedChainIds: [config.web3.chain.chainId],
});
