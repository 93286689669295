import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import EventEmitter from 'events';
import PropTypes from 'prop-types';
import { FadeInTransition } from '@digigear/elements';

import Footer from './Footer';
import Header from './Header';
import WalletModalProvider from './WalletModalProvider';
import '@fontsource/m-plus-1/400.css';
import '@fontsource/m-plus-1/600.css';
import '@fontsource/m-plus-1/700.css';
import '@fontsource/m-plus-1/800.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import 'react-toastify/dist/ReactToastify.css';
import './toastify.css';
// Temp workaround for CSS import bug
import './wallet-adapter-styles.css';

export const routeUpdateEmitter = new EventEmitter();

const queryClient = new QueryClient();

const Layout = ({ children }) => {
  const [pathName, setPathName] = useState(null);

  const routeUpdateListener = useCallback(pathName => {
    setPathName(pathName);
  }, []);

  useEffect(() => {
    routeUpdateEmitter.on('routeUpdate', routeUpdateListener);

    return () => routeUpdateEmitter.off('routeUpdate', routeUpdateListener);
  }, [routeUpdateListener]);

  return (
    <>
      <WalletModalProvider>
        <QueryClientProvider client={queryClient}>
          <Header />
          {/* When pathName changes, the FadeInTransition is remounted to trigger fade-in transition */}
          <FadeInTransition key={'path-' + pathName}>
            <div
              style={{
                background:
                  'linear-gradient(205.3deg, #1c2022 16.05%, #06162f 83.95%)',
              }}
            >
              {children}
            </div>
          </FadeInTransition>
          <Footer />
        </QueryClientProvider>
      </WalletModalProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
