import React from 'react';
import styled, { css } from 'styled-components';
import { BaseButton } from '@digigear/elements';

const StyledButton = styled(BaseButton)`
  padding: 16px 24px;

  border: 2px solid ${props => props.theme.palette.primary.main};
  border-radius: 5px;

  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;

  text-decoration: none;
  justify-content: center;

  align-items: center;

  filter: drop-shadow(0px 0px 4px #50eaff);

  transition: filter 0.2s;

  &:hover {
    filter: drop-shadow(0px 0px 8px #50eaff);
  }
  &:active {
    filter: drop-shadow(0px 0px 8px #50eaff);
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;
