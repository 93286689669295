import { Config } from './config';

const config: Config = {
  web3: {
    chain: {
      chainId: 4,
      rpcUrl: 'https://rinkeby.infura.io/v3/722606095eae42c38f40c6c132fc2384',
      explorerUrl: 'https://rinkeby.etherscan.io/tx',
      gasSymbol: 'ETH',
    },

    addresses: {
      ERC721: '0x5C8ce4277B5CD9f544a114dF63f9EF7041Ad8AC2',
      Multicall: '0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821',
    },
  },

  backendUrl: 'https://backend.development.racehorsenft.io/api/v1',

  whitelistUrl:
    'https://3vcr2jdvhottc46lfoocjjfpii0gkwlz.lambda-url.ap-east-1.on.aws/api/v1/whitelists/racehorse-nft-whitelist',
};

export default config;
