import React from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';

import Button from './Button';
import { useWalletModal } from './WalletModalProvider';

const StyledWalletButton = styled(Button)``;

const formatPublicKey = str => {
  return str.substr(0, 5) + '...' + str.substr(str.length - 4, 4);
};

/**
 * @param {{
 *  as?: string
 * }}
 */
const WalletButton = ({ as }) => {
  const { visible, setVisible } = useWalletModal();
  const { active, account } = useWeb3React();

  if (active && account !== null) {
    return (
      <StyledWalletButton className="public-key" as={as}>
        <span>{formatPublicKey(account)}</span>
      </StyledWalletButton>
    );
  } else if (visible) {
    return <StyledWalletButton as={as}>Connecting</StyledWalletButton>;
  } else {
    return (
      <StyledWalletButton
        as={as}
        onClick={e => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        Connect Wallet
      </StyledWalletButton>
    );
  }
};

export default WalletButton;
