/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/shim';
import * as React from 'react';
import Web3 from 'web3/dist/web3.min';
import { TrackingContext } from '@digigear/elements';

import { routeUpdateEmitter } from './src/components/Layout';
import { PageProviders, RootProviders } from './src/providers';
import { trackingContext } from './src/tracking-browser';

export function onRouteUpdate({ location, prevLocation }) {
  // wrap inside a timeout to make sure react-helmet is done with its changes
  // (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;

    trackingContext.trackEvent({ event: 'page_view', page_path: pagePath });
  };
  requestAnimationFrame(() => {
    requestAnimationFrame(sendPageView);
  });

  routeUpdateEmitter.emit('routeUpdate', location.pathname);
}

function getLibrary(provider) {
  return new Web3(provider);
}

export function wrapRootElement({ element }) {
  return (
    <TrackingContext.Provider value={trackingContext}>
      <RootProviders getWeb3Library={getLibrary}>{element}</RootProviders>
    </TrackingContext.Provider>
  );
}

export function wrapPageElement({ element }) {
  return <PageProviders>{element}</PageProviders>;
}
